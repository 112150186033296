import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Stratégie d\'Entreprise',
        description: 'Développement de plans d\'affaires stratégiques basés sur des insights d\'IA pour optimiser la performance et la croissance du marché.'
    },
    {
        icon: <FiLayers />,
        title: 'Développement de Sites Web',
        description: 'Création de sites web réactifs et pilotés par IA pour améliorer l\'expérience utilisateur et stimuler l\'engagement.'
    },
    {
        icon: <FiUsers />,
        title: 'Marketing & Reporting',
        description: 'Utilisation de l\'IA pour automatiser et optimiser les campagnes marketing, accompagnée de rapports de performance détaillés.'
    },
    {
        icon: <FiMonitor />,
        title: 'Développement d\'Applications Mobiles',
        description: 'Création d\'applications mobiles intuitives intégrant des fonctionnalités IA pour améliorer l\'interaction et la satisfaction des utilisateurs.'
    },
    {
        icon: <FiUsers />,
        title: 'Génération de Contenu',
        description: 'Utilisation d\'outils IA comme GPT-4 pour générer du contenu de haute qualité pour les articles, les posts et les emails.'
    },
    {
        icon: <FiMonitor />,
        title: 'Email Marketing',
        description: 'Automatisation des campagnes email avec des plateformes pilotées par IA comme Mailchimp et Sendinblue pour une meilleure portée et engagement.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);

        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="/service-details">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
